import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography, Button, withStyles, colors, makeStyles } from '@material-ui/core';
import ImageCard from './ImageCard';
import { pageTitles } from '../../constants';
const { deepOrange } = colors;

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    '&:hover': {
      backgroundColor: deepOrange[700],
    },
  },
}))(Button);

const useStyles = makeStyles({
  logo: {
    width: '100%',
  },
});

export default function Landing(props: any) {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    document.title = pageTitles.LANDING;
  });

  const goToEmailForm = () => {
    history.push('/enter');
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box mt="40px" mb="30px">
          <Typography variant="h4" gutterBottom>
            Follow all government guidelines - But if you must travel
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <ImageCard image="/static/images/Picture1.png" heading="Input your past/future trips">
            Secured, encrypted and privacy compliant
          </ImageCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ImageCard
            image="/static/images/Picture2.png"
            heading="Get Travel Risk Score based on live data, and data science">
            Dynamic risk score created using public health, travel, discretionary spend data,
            combined with anonymized and aggregated data from over 270 global travel brands
          </ImageCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ImageCard
            image="/static/images/Picture3.png"
            heading="Play your part by staying informed">
            Email updates with opt-out
          </ImageCard>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box my="30px" textAlign="center">
          <ColorButton variant="contained" size="large" onClick={goToEmailForm}>
            Travel Risk Score
          </ColorButton>
        </Box>
        <Box my="30px" textAlign="center">
          <Typography variant="h6">Click here to protect your loved ones and yourself</Typography>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        spacing={3}
        justify="center"
        alignItems="center">
        {/* Other Logos pending approval for use
          <Grid item xs={12} sm={6} md={4}>
            <Box my="30px" textAlign="center">
              <img
                className={classes.logo}
                src="/static/images/john-hopkins-logo.jpg"
                alt="JHU"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box my="30px" px="30px" textAlign="center">
              <img className={classes.logo} src="/static/images/US_CDC_logo.svg" alt="CDC" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box my="30px" textAlign="center">
              <img className={classes.logo} src="/static/images/who-logo-rgb.png" alt="WHO" />
            </Box>
          </Grid>
          */}
        <Grid item xs={12} sm={6} md={4}>
          <Box textAlign="center">
            <img
              className={classes.logo}
              src="/static/images/Datavant-_-BlueBlack.png"
              alt="Datavant"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box px="10px" textAlign="center">
            <img className={classes.logo} src="/logo.png" alt="ADARA" />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box my="30px" textAlign="center">
          <Typography component="p">
            This tool does not track your location, and any personal data is protected according to
            regulatory compliance standards. Anonymous data is used only to improve the scoring
            methods and to help analyze and provide insights to assist you in making informed travel
            choices.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
