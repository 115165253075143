import React, { useState, useEffect } from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function FaqPage(props: any) {
  const {
    question = 'Question',
    answer = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.',
    onChange = () => {},
    expanded: outerExpanded = false,
  } = props;
  const [expanded, setExpanded] = useState<boolean>(outerExpanded);

  useEffect(() => {
    setExpanded(outerExpanded);
  }, [outerExpanded]);

  const handleChange = (event: any, newExpanded: boolean) => {
    setExpanded(newExpanded);
    onChange(event, newExpanded);
  };

  const classes = useStyles();
  return (
    <ExpansionPanel
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={handleChange}>
      <ExpansionPanelSummary>
        <Typography className={classes.heading}>{question}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{answer}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
