import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import { signUp } from '../../services/CovidService';
import { InfoMessageSnackbar, ErrorMessageSnackbar } from '../../common/SnackBars';
import { ValidationTextField, NextButton } from '../../common/FormComponents';
import { PrivacyPolicyLink, PrivacySettingLink } from '../../common/Links';
import { pageTitles } from '../../constants';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  bulletIcon: {
    fontSize: '9px',
  },
}));

export default function EmailForm() {
  const classes = useStyles();
  const { loading, setLoading } = useContext(AppContext);
  let history = useHistory();
  const [emailValue, setEmailValue] = useState('');
  const [emailFieldValidation, setEmailFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });
  const isFirstRun = useRef(true);
  const isEmailPristine = useRef(true);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState({
    show: false,
    message: '',
  });
  const [formInfoMessage, setFormInfoMessage] = useState({
    show: false,
    message: '',
  });

  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);

  useEffect(() => {
    document.title = pageTitles.EMAIL;
  });

  const validateEmailField = useCallback(() => {
    if (emailValue !== '') {
      setEmailFieldValidation({
        valid: true,
        errorMessage: '',
      });
      return true;
    } else {
      setEmailFieldValidation({
        valid: false,
        errorMessage: 'Required',
      });
      return false;
    }
  }, [emailValue]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else {
      validateEmailField();
    }
  }, [emailValue, validateEmailField]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isEmailPristine.current) {
      isEmailPristine.current = false;
    }
    setFormErrorMessage({
      show: false,
      message: '',
    });

    if (validateEmailField()) {
      setLoading(true);
      setFormDisabled(true);

      signUp(emailValue)
        .then(() => {
          const encodedEmail = window.btoa(emailValue);
          history.push({
            pathname: '/token',
            search: `?e=${encodedEmail}`,
          });
        })
        .catch(err => {
          setFormErrorMessage({
            show: true,
            message: err['message'],
          });
          setFormDisabled(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit} action="" noValidate autoComplete="off">
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={9} md={8} lg={7}>
          <Box mt="15px" textAlign="center">
            <Typography variant="h6">
              Welcome! If have traveled in the past or are planning future travel, use the Travel
              Risk Tracker to stay informed.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" xs={12} sm={7} md={5} lg={5}>
          <Grid item xs={12}>
            <ValidationTextField
              className="text-field-outline"
              label="Email address"
              variant="outlined"
              type="email"
              InputLabelProps={{
                id: 'email-label',
              }}
              inputProps={{
                'aria-labelledby': 'email-label',
              }}
              helperText={emailFieldValidation.errorMessage}
              margin="normal"
              error={!emailFieldValidation.valid && !isEmailPristine.current}
              disabled={formDisabled}
              onChange={e => {
                if (isEmailPristine.current) {
                  isEmailPristine.current = false;
                }
                setEmailValue(e.target.value);
              }}
              onBlur={e => {
                if (isEmailPristine.current) {
                  isEmailPristine.current = false;
                }
                validateEmailField();
              }}
              fullWidth
              required
            />
            <Box mt="15px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptPrivacyPolicy}
                    onChange={(event: any) => {
                      setAcceptPrivacyPolicy(!!event.target.checked);
                    }}
                    name="privacyPolicy"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    I consent to receiving email communications and agree to the processing and
                    storing of my personal data, including inferred travel risk data, as described
                    in ADARA’s <PrivacyPolicyLink text="Privacy Promise" />.
                    <br />
                    <br />
                    You may opt out at any time in <PrivacySettingLink />.
                  </Typography>
                }
              />
            </Box>
            <ErrorMessageSnackbar
              show={formErrorMessage.show}
              message={formErrorMessage.message}
              onClose={(e: any) => {
                setFormErrorMessage({
                  show: false,
                  message: '',
                });
              }}
            />
            <InfoMessageSnackbar
              show={formInfoMessage.show}
              message={formInfoMessage.message}
              onClose={(e: any) => {
                setFormInfoMessage({
                  show: false,
                  message: '',
                });
              }}
            />
            <NextButton
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disabled={!acceptPrivacyPolicy || !emailFieldValidation.valid || formDisabled}
              fullWidth>
              {loading ? <CircularProgress /> : 'Next'}
            </NextButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
