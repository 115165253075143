import { withStyles, TextField, Button } from '@material-ui/core';

export const ValidationTextField = withStyles({
  root: {
    '& > label': {
      fontFamily: 'Raleway',
      fontSize: '1em',
      fontWeight: '500',
    },
    '& input': {
      fontFamily: 'Raleway',
      fontSize: '1em',
    },
    '& input[type="password"]': {
      '-webkit-text-security': 'disc !important;',
      fontFamily: 'caption',
      letterSpacing: '.1em',
    },
    '& > .MuiFormHelperText-root': {
      fontFamily: 'Raleway',
    },
  },
})(TextField);

export const NextButton = withStyles({
  root: {
    textTransform: 'capitalize',
    marginTop: '50px',
    padding: '12px',
  },
  label: {
    fontFamily: 'Raleway',
    fontSize: '1em',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
})(Button);
