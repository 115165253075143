import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { get, reduce, find } from 'lodash';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import { addTrips, getTrip } from '../../services/CovidService';
import { ErrorMessageSnackbar } from '../../common/SnackBars';
import { NextButton } from '../../common/FormComponents';
import { pageTitles } from '../../constants';
import { useQueryParams } from '../../utils/utils';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Divider,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import AutocompleteField from './AutocompleteField';

export default function TripsForm(props: any) {
  useEffect(() => {
    document.title = pageTitles.TRIPS;
  });

  let history = useHistory();
  const queryParams = useQueryParams();
  const emailEncoded = queryParams.get('e') || '';
  const userCode = queryParams.get('uc') || '';
  const tripId = queryParams.get('tid') || null;
  const { loading, setLoading } = useContext(AppContext);

  const prospectiveSupportedDays = 14;
  const prospectiveMomentDate = moment().add(prospectiveSupportedDays, 'days');

  //
  //  Form fields
  //
  const [homeCity, setHomeCity] = useState<any>();
  const [tripStartDate, setTripStartDate] = useState<any>(moment().subtract(1, 'day'));
  const [tripEndDate, setTripEndDate] = useState<any>(moment().subtract(1, 'day'));
  const [travelPurpose, setTravelPurpose] = useState<any>('business');
  const [prospective, setProspective] = useState<any>('false');
  const [rideShareUsed, setRideShareUsed] = useState<any>('true');
  const [citiesVisited, setCitiesVisited] = useState<any>([]);
  const [airportsVisited, setAirportsVisited] = useState<any>([]);

  //
  //  Form validation
  //
  const [formDisabled, setFormDisabled] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState({
    show: false,
    message: '',
  });

  const [tripDateFieldValidation, setTripDateFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });
  const [homeCityFieldValidation, setHomeCityFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });
  const [citiesFieldValidation, setCitiesFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });
  const [airportsFieldValidation, setAirportsFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });

  const isFirstRun = useRef(true);
  const isTripDateFieldPristine = useRef(true);
  const isHomeCityFieldPristine = useRef(true);
  const isCitiesFieldPristine = useRef(true);
  const isAirportsFieldPristine = useRef(true);

  const validateHomeCityField = useCallback(() => {
    if (!homeCity) {
      setHomeCityFieldValidation({
        valid: false,
        errorMessage: 'Required',
      });
      return false;
    } else {
      setHomeCityFieldValidation({
        valid: true,
        errorMessage: '',
      });
      return true;
    }
  }, [homeCity]);

  const validateCitiesAirportsField = useCallback(() => {
    if (!citiesVisited.length && !airportsVisited.length) {
      setCitiesFieldValidation({
        valid: false,
        errorMessage: 'At least one city or airport is required',
      });
      setAirportsFieldValidation({
        valid: false,
        errorMessage: 'At least one city or airport is required',
      });
      return false;
    } else if (citiesVisited.length > 5 || airportsVisited.length > 5) {
      if (citiesVisited.length > 5) {
        setCitiesFieldValidation({
          valid: false,
          errorMessage: 'Max 5',
        });
      } else {
        setCitiesFieldValidation({
          valid: true,
          errorMessage: '',
        });
      }
      if (airportsVisited.length > 5) {
        setAirportsFieldValidation({
          valid: false,
          errorMessage: 'Max 5',
        });
      } else {
        setAirportsFieldValidation({
          valid: true,
          errorMessage: '',
        });
      }
      return false;
    } else {
      setCitiesFieldValidation({
        valid: true,
        errorMessage: '',
      });
      setAirportsFieldValidation({
        valid: true,
        errorMessage: '',
      });
      return true;
    }
  }, [citiesVisited, airportsVisited]);

  const validateTripDates = useCallback(() => {
    if (tripStartDate.isAfter(tripEndDate)) {
      setTripDateFieldValidation({
        valid: false,
        errorMessage: 'Start date cannot be after end date',
      });
      return false;
    } else {
      setTripDateFieldValidation({
        valid: true,
        errorMessage: '',
      });
      return true;
    }
  }, [tripStartDate, tripEndDate]);

  // If tripId is provided in query params,
  // -> prepopulate the trip form by fetching trip
  useEffect(() => {
    if (tripId) {
      setFormDisabled(true);
      getTrip(userCode, tripId)
        .then(tripList => {
          if (tripList.length) {
            const trip = find(tripList, { id: tripId });

            if (trip) {
              setTripStartDate(moment(trip.start));
              setTripEndDate(moment(trip.end));
              setTravelPurpose(trip.purpose);
              setRideShareUsed(trip.rideshare ? 'true' : 'false');
              setHomeCity(trip.home);
              if (trip['airports']) {
                setAirportsVisited(trip.airports);
              }
              if (trip['cities']) {
                setCitiesVisited(trip.cities);
              }
              setProspective(trip.prospective ? 'true' : 'false');
            }
          }
        })
        .finally(() => {
          setFormDisabled(false);
        });
    }
  }, [tripId, userCode]);

  // validate cities and airports fields
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else {
      if (!isTripDateFieldPristine.current) {
        validateTripDates();
      }
      if (!isCitiesFieldPristine.current || !isAirportsFieldPristine.current) {
        validateCitiesAirportsField();
      }
      if (!isHomeCityFieldPristine.current) {
        validateHomeCityField();
      }
    }
  }, [
    citiesVisited,
    airportsVisited,
    homeCity,
    validateCitiesAirportsField,
    validateHomeCityField,
    validateTripDates,
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isHomeCityFieldPristine.current) {
      isHomeCityFieldPristine.current = false;
    }
    if (isCitiesFieldPristine.current) {
      isCitiesFieldPristine.current = false;
    }
    if (isAirportsFieldPristine.current) {
      isAirportsFieldPristine.current = false;
    }
    setFormErrorMessage({
      show: false,
      message: '',
    });

    let isFormValid = reduce(
      [validateTripDates, validateHomeCityField, validateCitiesAirportsField],
      (isValid: any, fn: any) => {
        return fn() && isValid;
      },
      true
    );

    if (isFormValid) {
      setFormDisabled(true);
      setLoading(true);

      const trip = {
        home: homeCity,
        start: moment(tripStartDate).format('YYYY-MM-DD'),
        end: moment(tripEndDate).format('YYYY-MM-DD'),
        prospective: prospective === 'true',
        purpose: travelPurpose,
        rideshare: rideShareUsed === 'true',
        cities: citiesVisited,
        airports: airportsVisited,
      };

      addTrips(userCode, [trip])
        .then((res: any) => {
          setLoading(false);
          const tripId = get(res, '0.id');

          // attach trip ID to query params so that user may go back
          const searchParams = `?uc=${userCode}&e=${emailEncoded}&tid=${tripId}`;
          history.replace({
            pathname: history.location.pathname,
            search: searchParams,
          });

          history.push({
            pathname: '/score',
            search: searchParams,
          });
        })
        .catch((err: any) => {
          setLoading(false);
          setFormDisabled(false);
          setFormErrorMessage({
            show: true,
            message: err['message'],
          });
        });
    }
  };

  const shouldDisableStartDate = (day: any) => {
    if (prospectiveMomentDate.isSameOrBefore(day)) {
      return true;
    }

    return false;
  };

  const shouldDisableEndDate = (day: any) => {
    if (prospectiveMomentDate.isSameOrBefore(day)) {
      return true;
    }

    // disable dates that are before the trip start date
    const dayBeforeStartDate = moment(tripStartDate).subtract(1, 'day');
    if (day.isBefore(dayBeforeStartDate)) {
      return true;
    }

    return false;
  };

  const tripEndDateComponent = (
    <Tooltip
      title={`Due to fast changing COVID-19
    health development and
    government policies/guidelines we
    do not provide tracking for
    itineraries more than ${prospectiveSupportedDays} days at
    this time.`}>
      <Box>
        <KeyboardDatePicker
          disabled={formDisabled}
          disableToolbar
          inputVariant="outlined"
          variant="dialog"
          format="MM/DD/YYYY"
          shouldDisableDate={shouldDisableEndDate}
          margin="normal"
          label="End Date"
          value={tripEndDate}
          onError={(error: any) => {}}
          onChange={value => {
            if (isTripDateFieldPristine.current) {
              isTripDateFieldPristine.current = false;
            }
            setTripEndDate(value);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change end date',
          }}
        />
      </Box>
    </Tooltip>
  );

  const tripValidationErroMessage = (
    <Box
      fontSize={14}
      mt={1}
      color="error.main"
      fontFamily="Roboto"
      fontWeight="400"
      lineHeight="1.5"
      letterSpacing="0.00938em">
      {tripDateFieldValidation.errorMessage}
    </Box>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <form onSubmit={handleSubmit} action="" noValidate autoComplete="off">
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={8} md={10}>
            <Box mb="20px">
              <Typography component="p">
                Enter travel details to calculate your Travel Risk Score:
              </Typography>
            </Box>
          </Grid>

          <Grid container item direction="row" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant="body1">Travel Dates</Typography>
            </Grid>
            <Grid item xs={7} sm={3}>
              <KeyboardDatePicker
                disabled={formDisabled}
                disableToolbar
                inputVariant="outlined"
                variant="dialog"
                format="MM/DD/YYYY"
                shouldDisableDate={shouldDisableStartDate}
                margin="normal"
                label="Start Date"
                value={tripStartDate}
                onChange={value => {
                  if (isTripDateFieldPristine.current) {
                    isTripDateFieldPristine.current = false;
                  }
                  setTripStartDate(value);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
              <Hidden smUp>
                {tripEndDateComponent}
                {tripValidationErroMessage}
              </Hidden>
            </Grid>
            <Hidden smUp>
              <Grid item xs={5}></Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid item sm={2} md={1}>
                <Box textAlign="center">To</Box>
              </Grid>
              <Grid item sm={3} md={3}>
                {tripEndDateComponent}
              </Grid>
              <Grid item sm={4}></Grid>
              <Grid item sm={8}>
                {tripValidationErroMessage}
              </Grid>
            </Hidden>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant="body1">Travel Purpose</Typography>
            </Grid>
            <Grid item xs={7} sm={8}>
              <Box display="inline-flex">
                <RadioGroup
                  row
                  aria-label="travel purpose"
                  name="travel-purpose"
                  value={travelPurpose}
                  onChange={event => {
                    setTravelPurpose(event.target.value);
                  }}>
                  <FormControlLabel
                    value="business"
                    disabled={formDisabled}
                    control={<Radio />}
                    label="Business"
                  />
                  <FormControlLabel
                    value="conference"
                    disabled={formDisabled}
                    control={<Radio />}
                    label="Conference"
                  />
                  <FormControlLabel
                    value="family_vacation"
                    disabled={formDisabled}
                    control={<Radio />}
                    label="Family Vacation"
                  />
                  <FormControlLabel
                    value="returning_home"
                    disabled={formDisabled}
                    control={<Radio />}
                    label="Going Home"
                  />
                </RadioGroup>
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant="body1">Ride Share Used</Typography>
            </Grid>
            <Grid item xs={7} sm={8}>
              <Box display="inline-flex">
                <RadioGroup
                  row
                  aria-label="ride share used"
                  name="ride-share-used"
                  value={rideShareUsed}
                  onChange={event => {
                    setRideShareUsed(event.target.value);
                  }}>
                  <FormControlLabel
                    value="true"
                    disabled={formDisabled}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    disabled={formDisabled}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box my="10px">
                <Typography variant="body1">Home City</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box my="10px">
                <AutocompleteField
                  type="city"
                  label="City"
                  multiple={false}
                  disabled={formDisabled}
                  value={homeCity}
                  onChange={(val: any) => {
                    if (isHomeCityFieldPristine.current) {
                      isHomeCityFieldPristine.current = false;
                    }
                    setHomeCity(val);
                  }}
                />
                <Box
                  fontSize={14}
                  mt={1}
                  color="error.main"
                  fontFamily="Roboto"
                  fontWeight="400"
                  lineHeight="1.5"
                  letterSpacing="0.00938em">
                  {homeCityFieldValidation.errorMessage}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box my="10px">
                <Typography variant="body1">What Cities you've visited or plan to visit</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box my="10px">
                <AutocompleteField
                  type="city"
                  multiple={true}
                  disabled={formDisabled}
                  value={citiesVisited}
                  onChange={(val: any) => {
                    if (isCitiesFieldPristine.current) {
                      isCitiesFieldPristine.current = false;
                    }
                    setCitiesVisited(val);
                  }}
                />
                <Box
                  fontSize={14}
                  mt={1}
                  color="error.main"
                  fontFamily="Roboto"
                  fontWeight="400"
                  lineHeight="1.5"
                  letterSpacing="0.00938em">
                  {citiesFieldValidation.errorMessage}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box my="10px">
                <Typography variant="body1">
                  What Airports you've visited or plan to visit
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box my="10px">
                <AutocompleteField
                  type="airport"
                  multiple={true}
                  disabled={formDisabled}
                  value={airportsVisited}
                  onChange={(val: any) => {
                    if (isAirportsFieldPristine.current) {
                      isAirportsFieldPristine.current = false;
                    }
                    setAirportsVisited(val);
                  }}></AutocompleteField>
                <Box
                  fontSize={14}
                  mt={1}
                  color="error.main"
                  fontFamily="Roboto"
                  fontWeight="400"
                  lineHeight="1.5"
                  letterSpacing="0.00938em">
                  {airportsFieldValidation.errorMessage}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center" justify="center">
            <Grid item xs={12} sm={6} md={6} lg={8}>
              <Box mt="15px">
                <Typography variant="body2">
                  Disclaimer: This tool does not provide medical or health advice, including through
                  the use of the Travel Risk Score. The score is based on travel location insights
                  and is provided for general interest only and without warranty of any kind. You
                  should not rely on the score to make medical related decisions, or otherwise, and
                  should consult available information and seek professional advice where
                  appropriate.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Hidden xsDown>
              <Grid item sm={3} md={3} lg={2}></Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={6} lg={8}>
              <Box>
                <ErrorMessageSnackbar
                  show={formErrorMessage.show}
                  message={formErrorMessage.message}
                  onClose={() => {
                    setFormErrorMessage({
                      show: false,
                      message: '',
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center">
            <Hidden xsDown>
              <Grid item sm={3} md={4}></Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={4}>
              <Box mb="20px">
                <NextButton
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={
                    (!isTripDateFieldPristine.current && !tripDateFieldValidation.valid) ||
                    (!isHomeCityFieldPristine.current && !homeCityFieldValidation.valid) ||
                    ((!isCitiesFieldPristine.current || !isAirportsFieldPristine.current) &&
                      (!citiesFieldValidation.valid || !airportsFieldValidation.valid)) ||
                    formDisabled
                  }
                  fullWidth>
                  {loading ? <CircularProgress /> : 'Submit'}
                </NextButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
}
