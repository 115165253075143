import React, { createContext, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Landing from './components/Landing/Landing';
import EmailForm from './components/Email/EmailForm';
import TripsForm from './components/Trips/TripsForm';
import TokenForm from './components/Token/TokenForm';
import ScorePage from './components/Score/ScorePage';
import FaqPage from './components/Faq/Faq';
import OptOut from './components/OptOut/OptOut';
import Header from './components/Header';
import Footer from './components/Footer';
import { appTheme } from './constants';

const theme = createMuiTheme(appTheme);

type AppContextProps = {
  loading: boolean;
  setLoading: Function;
};

export const AppContext = createContext<AppContextProps>({
  loading: false,
  setLoading: () => {},
});

function App() {
  const [loading, setLoading] = useState(false);

  const context = {
    loading,
    setLoading,
  };

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={theme}>
        <Router>
          <Container className="app" maxWidth="lg">
            <Box mb="50px">
              <Switch>
                <Route exact path="/">
                  <Landing />
                </Route>
                <Route exact path="/enter">
                  <Header />
                  <EmailForm />
                </Route>
                <Route exact path="/token">
                  <Header />
                  <TokenForm />
                </Route>
                <Route path="/trips">
                  <Header />
                  <TripsForm />
                </Route>
                <Route path="/score">
                  <Header />
                  <ScorePage />
                </Route>
                <Route path="/faq">
                  <Header />
                  <FaqPage />
                </Route>
                <Route path="/optout">
                  <Header />
                  <OptOut />
                </Route>
              </Switch>
            </Box>
            <Footer />
          </Container>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
