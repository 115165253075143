import React, { useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

import FaqSection from './FaqSection';
import { FAQ, pageTitles } from '../../constants';

export default function FaqPage() {
  useEffect(() => {
    document.title = pageTitles.FAQ;
  });

  return (
    <>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box my="15px">
            <Typography variant="h6">FAQ</Typography>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          {FAQ.map(({ header, questions }, index) => {
            return (
              <Grid item xs={12} key={index}>
                <FaqSection header={header} questions={questions} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
