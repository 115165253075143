import { useLocation } from 'react-router-dom';

/**
 * A custom hook that builds on useLocation to parse the query string
 */
export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Wraps an XHR request to return the error message that is returned by the backend
 * @param requestPromise {Promise} The request promise
 */
export function requestErrorWrapper(requestPromise: any) {
  const wrappedPromise = new Promise<any>((resolve, reject) => {
    requestPromise.then(resolve);
    requestPromise.catch((err: any) => {
      const { response, toJSON } = err;
      const { data } = response;
      const { detail, status } = data;
      let message;

      if (status === 400 || status === 403) {
        message = detail;
      } else if (toJSON) {
        message = toJSON();
      }

      reject({
        response,
        message,
      });
    });
  });

  return wrappedPromise;
}
