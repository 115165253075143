import React from 'react';
import { Box, Grid, Divider } from '@material-ui/core';

import {
  PrivacyPolicyLink,
  PrivacySettingLink,
  FeedbackLink,
  MuiRouterLink as Link,
} from '../common/Links';

export default function Footer() {
  return (
    <Box mt="100px" mb="25px">
      <Grid container justify="center" alignItems="center">
        <Box mx="20px">
          <PrivacyPolicyLink text="Privacy Promise" />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box mx="20px">
          <PrivacySettingLink />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box mx="20px">
          <Link to="/faq">FAQ</Link>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box mx="20px">
          <FeedbackLink />
        </Box>
      </Grid>
    </Box>
  );
}
