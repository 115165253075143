import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { useQueryParams } from '../../utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import { validate } from '../../services/CovidService';
import { InfoMessageSnackbar, ErrorMessageSnackbar } from '../../common/SnackBars';
import { ValidationTextField, NextButton } from '../../common/FormComponents';
import { pageTitles } from '../../constants';
import { Box, Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

export default function TokenForm() {
  const classes = useStyles();
  const { loading, setLoading } = useContext(AppContext);
  let history = useHistory();
  let location = useLocation();
  const queryParams = useQueryParams();
  const emailEncoded = queryParams.get('e') || '';
  const emailDecoded = window.atob(emailEncoded);
  const qToken = queryParams.get('token');
  const [passcodeValue, setPasscodeValue] = useState(qToken ? qToken : '');
  const [passcodeFieldValidation, setPasscodeFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });
  const isFirstRun = useRef(true);
  const isPasscodePristine = useRef(true);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState({
    show: false,
    message: '',
  });
  const [formInfoMessage, setFormInfoMessage] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    document.title = pageTitles.TOKEN;
  });

  const validatePasscodeField = useCallback(() => {
    if (passcodeValue !== '') {
      setPasscodeFieldValidation({
        valid: true,
        errorMessage: '',
      });
      return true;
    } else {
      setPasscodeFieldValidation({
        valid: false,
        errorMessage: 'Required',
      });
      return false;
    }
  }, [passcodeValue]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else {
      validatePasscodeField();
    }
  }, [passcodeValue, validatePasscodeField]);

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault();
    }

    callValidate(emailDecoded, passcodeValue);
  };

  const callValidate = useCallback(
    (email: any, token: any) => {
      if (isPasscodePristine.current) {
        isPasscodePristine.current = false;
      }
      setFormErrorMessage({
        show: false,
        message: '',
      });

      if (validatePasscodeField()) {
        setLoading(true);
        setFormDisabled(true);

        validate(email, token)
          .then((userCode: string) => {
            history.push({
              pathname: '/trips',
              search: `?uc=${userCode}&e=${emailEncoded}`,
            });
          })
          .catch(err => {
            setFormErrorMessage({
              show: true,
              message: err.message,
            });
            setFormDisabled(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [history, setLoading, validatePasscodeField, emailEncoded]
  );

  useEffect(() => {
    if (emailDecoded && qToken) {
      callValidate(emailDecoded, qToken);
    }
  }, [callValidate, location, emailDecoded, qToken]);

  return (
    <form className={classes.root} onSubmit={handleSubmit} action="" noValidate autoComplete="off">
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={8} md={8} xl={8}>
          <Box textAlign="center">
            <Typography component="p">
              For your security and privacy, a passcode was sent to the email address you submitted.
              Please enter it below:
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" xs={12} sm={6} md={6} xl={8}>
          <Grid item xs={12}>
            <ValidationTextField
              className="text-field-outline"
              label="Passcode"
              variant="outlined"
              type="password"
              value={passcodeValue}
              helperText={passcodeFieldValidation.errorMessage}
              margin="normal"
              error={!passcodeFieldValidation.valid && !isPasscodePristine.current}
              disabled={formDisabled}
              onChange={e => {
                if (isPasscodePristine.current) {
                  isPasscodePristine.current = false;
                }
                setPasscodeValue(e.target.value);
              }}
              onBlur={e => {
                if (isPasscodePristine.current) {
                  isPasscodePristine.current = false;
                }
                validatePasscodeField();
              }}
              fullWidth
              required
            />
            <br />
            <ErrorMessageSnackbar
              show={formErrorMessage.show}
              message={formErrorMessage.message}
              onClose={() => {
                setFormErrorMessage({
                  show: false,
                  message: '',
                });
              }}
            />
            <InfoMessageSnackbar
              show={formInfoMessage.show}
              message={formInfoMessage.message}
              onClose={() => {
                setFormInfoMessage({
                  show: false,
                  message: '',
                });
              }}
            />
            <NextButton
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disabled={!passcodeFieldValidation.valid || formDisabled}
              fullWidth>
              {loading ? <CircularProgress /> : 'Next'}
            </NextButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
