import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { includes, throttle, startCase } from 'lodash';

import { locations } from '../../services/CovidService';
import { LOCATION_TYPES } from '../../constants';

export default function AutocompleteField(props: any) {
  const {
    type = 'city',
    value = null,
    onChange = () => {},
    disabled,
    label: inputLabel,
    multiple,
  } = props;
  const locationType = type === LOCATION_TYPES.CITY ? 'city' : 'airport';
  const label = inputLabel
    ? inputLabel
    : type === LOCATION_TYPES.CITY
    ? 'Add a city'
    : 'Add an airport';
  const [selected, setSelected] = useState<any[]>(value);
  const [inputValue, setInputValue] = useState<any>('');
  const [options, setOptions] = useState<any[]>([]);
  const debounceTimeoutID = useRef<any>();

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSelectChange = (e: any, value: any, reason: string) => {
    // "create-option", "select-option", "remove-option", "blur" or "clear
    if (includes(['select-option', 'remove-option', 'clear', 'blur'], reason)) {
      setSelected(value);
      onChange(value);
    }
  };

  const fetch = useMemo(
    () =>
      throttle((query: string) => {
        return locations(locationType, query);
      }, 800),
    [locationType]
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (inputValue.length >= 3) {
      if (debounceTimeoutID.current) {
        clearTimeout(debounceTimeoutID.current);
      }

      debounceTimeoutID.current = setTimeout(() => {
        fetch(inputValue)
          .then((results: any) => {
            const data = results.data.response;
            if (active) {
              setOptions(data || []);
            }
          })
          .catch(err => {
            console.log(`GET LOCATIONS error ${err.message}`);
          });
      }, 500);
    }

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) => {
        if (type === LOCATION_TYPES.CITY) {
          if (option.country === 'US') {
            return `${startCase(option.city)}, ${option.state}, ${option.country}`;
          } else {
            return `${startCase(option.city)}, ${option.country}`;
          }
        } else {
          // airport
          return `${option.code}`;
        }
      }}
      noOptionsText={type === LOCATION_TYPES.CITY ? 'Input city' : 'Input airport'}
      filterOptions={x => x}
      autoComplete
      includeInputInList
      options={options}
      value={selected}
      onChange={handleSelectChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          onChange={handleInputChange}
          // helperText="Min 3 chars to search"
          InputProps={{
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
          }}
        />
      )}
      renderOption={(option: any) => {
        return (
          <Grid container alignItems="center">
            {type === LOCATION_TYPES.CITY ? (
              <Grid item xs>
                <span>
                  {option.country === 'US'
                    ? `${startCase(option.city)}, ${option.state}, ${option.country}`
                    : `${startCase(option.city)}, ${option.country}`}
                </span>
              </Grid>
            ) : (
              <Grid item xs>
                <span>
                  {option.code} - {option.name}
                </span>
                <Typography variant="body2" color="textSecondary">
                  {option.city}, {option.country}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
}
