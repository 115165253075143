import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, makeStyles, Grid, CircularProgress } from '@material-ui/core';
import { getNews, getScores, getTrip } from '../../services/CovidService';
import { useQueryParams } from '../../utils/utils';
import { TargetBlankLink } from '../../common/Links';
import { pageTitles } from '../../constants';
import { find, startCase, chain } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles({
  picture: {
    width: '100%',
  },
});

export default function ScorePage() {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const userCode = queryParams.get('uc') || '';
  const tripId = queryParams.get('tid');
  const [news, setNews] = useState<any>([]);
  const [score, setScore] = useState<any>({});

  const tripLoaded = useRef(false);
  const [tripStartDate, setTripStartDate] = useState<any>();
  const [tripEndDate, setTripEndDate] = useState<any>();
  const [homeCity, setHomeCity] = useState<any>();
  const [citiesVisited, setCitiesVisited] = useState<any>([]);

  useEffect(() => {
    document.title = pageTitles.SCORE;
  });

  useEffect(() => {
    if (tripId) {
      getNews(userCode, tripId)
        .then(news => {
          if (news) setNews(news);
        })
        .catch(err => {
          console.log(`GET NEWS error ${err.message}`);
        });

      getScores(userCode, tripId)
        .then(score => {
          if (score) setScore(score);
        })
        .catch(err => {
          console.log(`GET SCORE error ${err.message}`);
          const score = {
            display_score: 'N/A',
            description: 'Having trouble with retrieving your score. Please try again later.',
          };
          setScore(score);
        });

      getTrip(userCode, tripId).then(tripList => {
        if (tripList.length) {
          const trip = find(tripList, { id: tripId });

          if (trip) {
            setTripStartDate(moment(trip.start));
            setTripEndDate(moment(trip.end));
            setHomeCity(trip.home);
            if (trip['cities']) {
              setCitiesVisited(trip.cities);
            }
            tripLoaded.current = true;
          }
        }
      });
    }
  }, [userCode, tripId]);

  const renderCity = (city: any) => {
    if (city.country === 'US') {
      return `${startCase(city.city)}, ${city.state}, ${city.country}`;
    } else {
      return `${startCase(city.city)}, ${city.country}`;
    }
  };

  const renderDate = (date: any) => {
    return moment(date).format('M/D/YYYY');
  };

  const newsSection = news.length > 0 && (
    <Box my="25px">
      <Typography>Latest news about your trip:</Typography>
      {news.map((item: any, index: number) => {
        return (
          <Box key={index}>
            <TargetBlankLink href={item.link} color="initial" underline="always">
              {item.title}
            </TargetBlankLink>
          </Box>
        );
      })}
    </Box>
  );

  const renderScorePng = (score: number) => {
    const imageName = 'Score' + Math.trunc(score).toString();
    return (
      <Grid container spacing={1} justify="center">
        <Grid item xs={12} sm={8} md={6}>
          <img
            id="score-png"
            className={classes.picture}
            src={`/static/images/score-dial/${imageName}.png`}
            alt={imageName}
          />
        </Grid>
      </Grid>
    );
  };

  const scoreSection = (
    <>
      <Box my="25px">
        <Typography>
          Based on the information you provided, below is your Travel Risk Score:
        </Typography>
      </Box>

      <Box my="25px">
        <Typography>{score.description}</Typography>
      </Box>

      {tripLoaded.current ? (
        <Box my="25px">
          <Grid container direction="column" spacing={1}>
            <Grid container item>
              <Grid item xs={1} sm={3} md={4}></Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Box textAlign="left">Home/Origin:</Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Box textAlign="left">{renderCity(homeCity)}</Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={1} sm={3} md={4}></Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Box textAlign="left">Destination(s):</Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Box textAlign="left">
                  {chain(citiesVisited).map(renderCity).join('; ').value()}
                </Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={1} sm={3} md={4}></Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Box textAlign="left">Travel Dates:</Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Box textAlign="left">
                  {renderDate(tripStartDate)} to {renderDate(tripEndDate)}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : null}

      <Box my="25px">
        {!score.display_score ? (
          <CircularProgress />
        ) : parseInt(score.display_score, 10) >= -2 && parseInt(score.display_score, 10) <= 3 ? (
          // <Scale id="risk-score" value={score.score} />
          renderScorePng(score.score)
        ) : null}
      </Box>
    </>
  );

  return (
    <Box textAlign="center" alignItems="center">
      {/* <Box my="25px">
        <Typography>
          Based on the information you provided, below is your COVID-19 Exposure Risk Score is:
        </Typography>
      </Box> */}

      {scoreSection}

      <Box my="25px">
        <Typography>
          Recommended resources to help you understand and lower your travel risks:
        </Typography>
        <Box>
          <TargetBlankLink
            href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"
            color="initial"
            underline="always">
            CDC COVID-19 Resource Center
          </TargetBlankLink>
        </Box>
        <Box>
          <TargetBlankLink
            href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/events-as-they-happen"
            text="WHO COVID-19 Resource Center"
            color="initial"
            underline="always">
            WHO COVID-19 Resource Center
          </TargetBlankLink>
        </Box>
      </Box>
      {newsSection}
    </Box>
  );
}
