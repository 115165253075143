import axios from 'axios';
import { get } from 'lodash';
import axiosRetry from 'axios-retry';
import { COVID_SERVICE_API_URL } from '../constants';
import { requestErrorWrapper as errorWrapper } from '../utils/utils';

const client = axios.create({
  baseURL: COVID_SERVICE_API_URL,
  headers: {
    common: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  },
});

axiosRetry(client, {
  retries: 16,
  retryDelay: retryCount => {
    return retryCount * 1000;
  },
});

export const signUp = (email: string) => {
  return errorWrapper(
    client.post('/signup', {
      email,
    })
  );
};

export const validate = (email: string, passcode: string) => {
  return errorWrapper(
    client
      .post('/validate', {
        email,
        passcode,
      })
      .then(res => {
        return get(res, 'data.response.user_code');
      })
  );
};

export const addTrips = (userCode: string, trips: any) => {
  return errorWrapper(
    client
      .post('/trips', {
        user_code: userCode,
        trips,
      })
      .then(res => {
        return get(res, 'data.response');
      })
  );
};

export const getTrip = (userCode: string, tripId: string) => {
  return errorWrapper(
    client
      .get('/trips', {
        params: {
          user_code: userCode,
          trip_id: tripId,
        },
      })
      .then(res => {
        return get(res, 'data.response');
      })
  );
};

export const getNews = (userCode: string, tripId: string) => {
  return errorWrapper(
    client
      .get('/news', {
        params: {
          user_code: userCode,
          trip_id: tripId,
        },
      })
      .then(res => {
        return get(res, 'data.response');
      })
  );
};

export const getScores = (userCode: string, tripId: string) => {
  return client
    .get('/scores', {
      params: {
        user_code: userCode,
        trip_id: tripId,
      },
    })
    .then(res => {
      return get(res, 'data.response');
    });
};

export const locations = (locationType: string, query: string) => {
  return errorWrapper(
    client.get('/locations', {
      params: {
        location_type: locationType,
        s: query,
      },
    })
  );
};

export const optOut = (email: string) => {
  return errorWrapper(
    client
      .post('/optout', {
        email,
      })
      .then(res => {
        return res.status === 204;
      })
  );
};
