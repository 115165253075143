import React from 'react';
import { Box, IconButton, withStyles, SnackbarContent, colors } from '@material-ui/core';

import { ErrorIcon, CloseIcon, CheckCircleIcon } from './Icons';

const ErrorMessageSnackbarContent = withStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.error.dark,
    fontFamily: 'Raleway',
    marginTop: '20px',
    '& #client-snackbar': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '70vw',
    },
    '& #client-snackbar > svg': {
      marginRight: '5px',
    },
    '& .MuiSnackbarContent-action': {
      paddingLeft: 0,
    },
  },
  message: {},
}))(SnackbarContent);

const InfoMessageSnackbarContent = withStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: colors.green.A700,
    fontFamily: 'Raleway',
    marginTop: '20px',
    '& #client-snackbar': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '70vw',
    },
    '& #client-snackbar > svg': {
      marginRight: '5px',
    },
    '& .MuiSnackbarContent-action': {
      paddingLeft: 0,
    },
  },
  message: {},
}))(SnackbarContent);

export const ErrorMessageSnackbar = (props: any) => (
  <Box display={!props.show ? 'none' : ''}>
    <ErrorMessageSnackbarContent
      message={
        <span id="client-snackbar">
          <ErrorIcon />
          {props.message}
        </span>
      }
      action={[
        <IconButton key="close" href="" aria-label="close" onClick={props.onClose} color="inherit">
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </Box>
);

export const InfoMessageSnackbar = (props: any) => (
  <Box display={!props.show ? 'none' : ''}>
    <InfoMessageSnackbarContent
      message={
        <span id="client-snackbar">
          <CheckCircleIcon />
          {props.message}
        </span>
      }
      action={[
        <IconButton key="close" href="" aria-label="close" onClick={props.onClose} color="inherit">
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </Box>
);
