import React from 'react';
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TargetBlankLink } from '../common/Links';

export const COVID_SERVICE_API_URL = 'https://api.travelcovid-19.org/api/v1';
export const appTheme = {
  palette: {
    primary: {
      light: '#d63384',
      main: '#cc0066',
      dark: '#8e0047',
    },
    secondary: {
      light: '#845bad',
      main: '#663399',
      dark: '#4a2b68',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
    },
    background: {
      default: '#ffffff',
    },
  },
  input: {
    color: '#00000099',
  },
  zIndex: {
    mobileStepper: 1000 * 300,
    speedDial: 1050 * 300,
    appBar: 1100 * 300,
    drawer: 1200 * 300,
    modal: 1300 * 300,
    snackbar: 1400 * 300,
    tooltip: 1500 * 300,
  },
};

export const LOCATION_TYPES = {
  CITY: 'city',
  AIRPORT: 'AIRPORT',
};

export const FAQ = [
  {
    header: 'Travel Risk Score',
    questions: [
      {
        question: 'Beta - April 2020',
        answer:
          'This beta only allows for previous travel, and does not calculate US interstate travel risks {eg. traveling from San Francisco, as a home location, to Los Angeles will only provide a neutral score}. In the upcoming update prior to launch, future travel, as well as interstate travel in the US will be offered. Non US travel scoring currently varies by country, and is focused on cross border travel.',
      },
      {
        question: 'How is the Travel Risk Score calculated?',
        answer:
          'Travel Risk Score is based on the trip detail you provide. Based on the locations you traveled to as well as the dates, the model assesses the relative elevation in risk profile associated  with  the travel compared to staying in your home city.',
      },
      {
        question: 'What factors do you consider while calculating the score?',
        answer: 'Travel Risk Score is based on travel dates and cities visited during travel.',
      },
      {
        question: 'How accurate is the score?',
        answer:
          'Travel Risk Score is an indicator of relative elevation in the risk profile associated with the travel compared to staying in your home city. It is not an absolute score to signify if you are truly affected. You should continue to practice precaution and adhere with the local government guidelines to stay safe.',
      },
      {
        question: 'What data sets are used in calculating the Travel Risk Score?',
        answer:
          'Travel Risk Score uses public data sets made available by John Hopkins University, Bing.com along with  the proprietary data collected by ADARA from a network of over 200 travel data partners.',
      },
      {
        question: 'How do I read/understand the score?',
        answer: (
          <>
            <Box>
              Travel Risk Score Ranges between -2 to +3. The various Travel Risk Score values are
              defined below:
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableCell>Score</TableCell>
                  <TableCell>Description</TableCell>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>-2</TableCell>
                    <TableCell>
                      Your past itinerary did not increase your COVID-19 exposure risk. Risk at the
                      destination was lower than your home/origin.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>-1</TableCell>
                    <TableCell>
                      Your past itinerary did not increase your COVID-19 exposure risk. Risk at the
                      destination was lower than your home/origin.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>0</TableCell>
                    <TableCell>
                      The COVID-19 exposure risk of your past itinerary compared to exposure at
                      home/origin are similar.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      There is an increase of COVID-19 exposure risk in your past itinerary as
                      compared to exposure at home/origin. Use precautions and follow all local
                      guidance.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      There is an increase of COVID-19 exposure risk in your past itinerary as
                      compared to exposure at home/origin. Use precautions and follow all local
                      guidance.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>
                      There is a significant increase of COVID-19 exposure risk in your itinerary as
                      compared to exposures at home/origin. Use extra precautions and follow all
                      local guidance.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ),
      },
      {
        question: 'What transportation modes do you consider while traveling to derive score?',
        answer: 'The current Travel Risk Score model does not account for mode of transportation.',
      },
      {
        question:
          'Do you provide scores for international  travel? Or is this for US travel alone?',
        answer: (
          <ul>
            <li>The Travel Risk Score is available for international travel.</li>
            <li>For Travel in United States, level of granularity is at the State Level</li>
            <li>For Travel outside United States, level of granularity is at the Country level</li>
          </ul>
        ),
      },
    ],
  },
  {
    header: 'General Questions',
    questions: [
      {
        question: 'I am going to travel in 2 weeks? How do I get a score for the upcoming trip?',
        answer:
          'Coming Soon! We will soon be providing capability to assess Travel Risk Score for upcoming travel.',
      },
      {
        question: 'When do you think it will be safe for me to travel?',
        answer: (
          <span>
            Travel Risk Score helps you understand relative elevation in the risk profile associated
            with the travel compared to staying in your home city. However, it <b>does not</b>{' '}
            provide any recommendations or guidelines on when it is safe to travel. You should
            continue to practice precaution and adhere with the local government guidelines to stay
            safe.
          </span>
        ),
      },
      {
        question: 'I went to a restaurant/club/gathering while traveling, am I now exposed?',
        answer:
          'The current Travel Risk Score model does not account for attendance in various forms of public gatherings. Since some of the contagious diseases are spread more easily in close environments, there is a possibility of elevated risk when attending public events and gatherings. You should continue to practice precaution and adhere with the local government guidelines such as social distancing to stay safe.',
      },
      {
        question: 'Where can I get more information?',
        answer: (
          <>
            <Box>
              Below are some of the resources available to help you stay informed. As always, please
              follow the guidelines published by your local government authorities:
            </Box>
            <Box>
              <ul>
                <li>
                  <TargetBlankLink href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">
                    CDC COVID-19 Resource Center
                  </TargetBlankLink>
                </li>
                <li>
                  <TargetBlankLink href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">
                    How To Protect Yourself and Others
                  </TargetBlankLink>
                </li>
                <li>
                  <TargetBlankLink href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html">
                    What to Do if You Are Sick
                  </TargetBlankLink>
                </li>
              </ul>
            </Box>
          </>
        ),
      },
    ],
  },
  {
    header: 'Privacy Related',
    questions: [
      {
        question: 'Why do you need my email address?',
        answer:
          'Your email address is being used for identity verification purposes and delivery of the Traveler Risk Score.',
      },
      {
        question: 'How is my information used?',
        answer: (
          <Box>
            <ul>
              <li>
                Email Address
                <ul>
                  <li>
                    Upon registration we will send you a passcode to your registered email address
                    in order to verify your identity. We will deliver the Traveler Risk Score to
                    your registered email address.
                  </li>
                </ul>
              </li>
              <li>
                Itinerary Data
                <ul>
                  <li>
                    Your itinerary data is being used to calculate the Traveler Risk Score by
                    combining proprietary real time aggregated global traveler data with public data
                    sets using predictive modeling and AI.
                  </li>
                </ul>
              </li>
            </ul>
          </Box>
        ),
      },
      {
        question: 'What information do you retain?',
        answer:
          'We store the email address hashed and encrypted separate from the itinerary data and calculated score. You can request your data to be opt out or deleted via the Privacy Center or Subject Access Right.',
      },
      {
        question: 'Can I unsubscribe from your mailing list?',
        answer: 'You can unsubscribe via the unsubscribe link in the email.',
      },
      {
        question: 'Can I request my data to be opt out or deleted?',
        answer:
          'You can request your data to be opt out or deleted via the Privacy Center or Subject Access Right.',
      },
    ],
  },
];

export const pageTitles = {
  LANDING: 'Home - COVID-19 Travel Risk Tracker',
  EMAIL: 'Email - COVID-19 Travel Risk Tracker',
  TOKEN: 'Passcode - COVID-19 Travel Risk Tracker',
  TRIPS: 'Trips - COVID-19 Travel Risk Tracker',
  SCORE: 'Score - COVID-19 Travel Risk Tracker',
  PRIVACY: 'Privacy Setting - COVID-19 Travel Risk Tracker',
  FAQ: 'FAQ - COVID-19 Travel Risk Tracker',
};
