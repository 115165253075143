import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { get } from 'lodash';

import { AppContext } from '../../App';
import { InfoMessageSnackbar, ErrorMessageSnackbar } from '../../common/SnackBars';
import { ValidationTextField, NextButton } from '../../common/FormComponents';
import { pageTitles } from '../../constants';
import { optOut } from '../../services/CovidService';

declare var grecaptcha: any;

export default function OptOut() {
  useEffect(() => {
    document.title = pageTitles.PRIVACY;
  });
  const history = useHistory();
  const location = useLocation();
  const prevSearch = get(location, 'state.prevLocation.search', '');
  const prevQueryParams = new URLSearchParams(prevSearch);
  const emailEncoded = prevQueryParams.get('e');
  const { loading, setLoading } = useContext(AppContext);
  const [emailValue, setEmailValue] = useState(emailEncoded ? window.atob(emailEncoded) : '');
  const [emailFieldValidation, setEmailFieldValidation] = useState({
    valid: false,
    errorMessage: '',
  });
  const isFirstRun = useRef(true);
  const isEmailPristine = useRef(true);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState({
    show: false,
    message: '',
  });
  const [formInfoMessage, setFormInfoMessage] = useState({
    show: false,
    message: '',
  });
  const [acceptOptOut, setAcceptOptOut] = useState(false);
  const siteKey = '6Lcfg-gUAAAAANf8UieVZvrlQ-LAa9CzMLFd0jEs';

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isEmailPristine.current) {
      isEmailPristine.current = false;
    }
    setFormErrorMessage({
      show: false,
      message: '',
    });

    if (validateEmailField()) {
      setLoading(true);
      setFormDisabled(true);

      grecaptcha.ready(() => {
        grecaptcha.execute(siteKey, { action: 'optout' }).then((token: any) => {
          console.log(token);
          // TODO: verify recaptcha token through covid API service
        });
      });

      optOut(emailValue)
        .then(res => {
          if (res) {
            setFormInfoMessage({
              show: true,
              message: 'You have successfully opted out.',
            });
            setTimeout(() => {
              history.push({
                pathname: '/',
              });
            }, 5000);
          }
        })
        .catch(err => {
          setFormErrorMessage({
            show: true,
            message: err['message'],
          });
          setFormDisabled(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validateEmailField = useCallback(() => {
    if (emailValue !== '') {
      setEmailFieldValidation({
        valid: true,
        errorMessage: '',
      });
      return true;
    } else {
      setEmailFieldValidation({
        valid: false,
        errorMessage: 'Required',
      });
      return false;
    }
  }, [emailValue]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else {
      validateEmailField();
    }
  }, [emailValue, validateEmailField, acceptOptOut]);

  return (
    <form onSubmit={handleSubmit} action="" noValidate autoComplete="off">
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box my="15px">
            <Typography variant="h6">PRIVACY SETTING</Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={10} md={6} lg={5}>
          <Box my="15px">
            <Typography variant="body1">
              We take the protection of your data seriously and you can adjust your permission at
              any time.
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={10} md={6} lg={5}>
          <Box my="15px">
            <Typography variant="body1">Please input your email address:</Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={8} md={6} lg={5}>
          <Grid item xs={12}>
            <ValidationTextField
              className="text-field-outline"
              label="Email address"
              variant="outlined"
              type="email"
              InputLabelProps={{
                id: 'email-label',
              }}
              inputProps={{
                'aria-labelledby': 'email-label',
              }}
              value={emailValue}
              helperText={emailFieldValidation.errorMessage}
              margin="normal"
              error={!emailFieldValidation.valid && !isEmailPristine.current}
              disabled={formDisabled}
              onChange={e => {
                if (isEmailPristine.current) {
                  isEmailPristine.current = false;
                }
                setEmailValue(e.target.value);
              }}
              onBlur={e => {
                if (isEmailPristine.current) {
                  isEmailPristine.current = false;
                }
                validateEmailField();
              }}
              fullWidth
              required
            />
            <Box mt="15px">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptOptOut}
                    onChange={(event: any) => {
                      setAcceptOptOut(!!event.target.checked);
                    }}
                    disabled={formDisabled}
                    name="optOut"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    Opt out my personal data collected by the COVID-19 Travel Risk Tracker
                  </Typography>
                }
              />
            </Box>

            <ErrorMessageSnackbar
              show={formErrorMessage.show}
              message={formErrorMessage.message}
              onClose={(e: any) => {
                setFormErrorMessage({
                  show: false,
                  message: '',
                });
              }}
            />
            <InfoMessageSnackbar
              show={formInfoMessage.show}
              message={formInfoMessage.message}
              onClose={(e: any) => {
                setFormInfoMessage({
                  show: false,
                  message: '',
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={5} md={4} lg={3}>
          <NextButton
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            disabled={!acceptOptOut || !emailFieldValidation.valid || formDisabled}
            fullWidth>
            {loading ? <CircularProgress /> : 'Submit'}
          </NextButton>
        </Grid>
      </Grid>
    </form>
  );
}
