import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import FaqQuestion from './FaqQuestion';

export default function FaqSection(props: any) {
  const { header = 'Section', questions = [] } = props;
  const [expanded, setExpanded] = useState<any>(false);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box my="15px">
      <Typography variant="h6">{header}</Typography>
      {questions.map(({ question, answer }: any, index: any) => {
        const panelId = `${header}_${index.toString()}`;
        return (
          <FaqQuestion
            key={index}
            question={question}
            answer={answer}
            expanded={expanded === panelId}
            onChange={handleChange(panelId)}
          />
        );
      })}
    </Box>
  );
}
