import React from 'react';
import { SvgIcon } from '@material-ui/core';

export function ErrorIcon(props: any) {
  return React.createElement(
    SvgIcon,
    { ...props },
    React.createElement(
      'path',
      {
        d:
          'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z',
      },
      'Error'
    )
  );
}

export function CloseIcon(props: any) {
  return React.createElement(
    SvgIcon,
    { ...props },
    React.createElement(
      'path',
      {
        d:
          'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
      },
      'Close'
    )
  );
}

export function CheckCircleIcon(props: any) {
  return React.createElement(
    SvgIcon,
    { ...props },
    React.createElement(
      'path',
      {
        d:
          'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z',
      },
      'CheckCircle'
    )
  );
}

export function CircleSolidIcon(props: any) {
  return React.createElement(
    SvgIcon,
    {
      viewBox: '0 0 512 512',
      ...props,
    },
    React.createElement(
      'path',
      {
        d: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
      },
      'CircleSolid'
    )
  );
}

export function ExpandIcon(props: any) {
  return React.createElement(
    SvgIcon,
    {
      width: '24',
      height: '24',
      ...props,
    },
    React.createElement(
      'g',
      {
        stroke: '#639',
        fill: 'none',
        fillRule: 'evenodd',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
      React.createElement(
        'path',
        {
          d: 'M23.5.5L14 10M10 14L.5 23.5M17 .5h6.5V7M.5 17v6.5H7',
        },
        'Expand'
      )
    )
  );
}

export function CollapseIcon(props: any) {
  return React.createElement(
    SvgIcon,
    {
      width: '24',
      height: '24',
      ...props,
    },
    React.createElement(
      'g',
      {
        stroke: '#639',
        fill: 'none',
        fillRule: 'evenodd',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
      React.createElement(
        'path',
        {
          d: 'M13.5 10.5l10-10M.5 23.5l10-10M20.5 10.5h-7v-7M10.5 20.5v-7h-7',
        },
        'Collapse'
      )
    )
  );
}
