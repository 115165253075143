import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function Header(props: any) {
  return (
    <>
      <Box textAlign="center" mt="50px" mb="20px">
        <Typography variant="h4">COVID-19 Travel Risk Tracker</Typography>
      </Box>
    </>
  );
}
